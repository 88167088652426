import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { SeachFieldWrapper } from "./elements";
import { H3, H5Big } from "./../Typography";
import Search from "./../icons/Search";
import { AnimationOnScroll } from "react-animation-on-scroll";

const SearchField = ({ searching, totalResults, totalCategories }) => {
  const { pageQAFSubTitle, pageQAFDescriptionOne } = useStaticQuery(graphql`
    query {
      craftPagesQafDefaultEntry(title: { eq: "Search" }) {
        id
        pageQAFSubTitle
        pageQAFDescriptionOne
      }
    }
  `).craftPagesQafDefaultEntry;
  const [searchValue, setSearchValue] = React.useState("");
  const [isPristine, setIsPristine] = React.useState(true);
  React.useEffect(() => {
    searching(searchValue);
  }, [searchValue]);

  const removeHTMLTags = (str) => {
    if (str === null || str === "") return false;
    else str = str.toString();

    return str.replace(/(<([^>]+)>)/gi, "");
  };

  return (
    <SeachFieldWrapper>
      <AnimationOnScroll
        animateIn="animate__fadeInLeft"
        animateOnce
        offset={50}
        // animateOut="animate__fadeOutLeft"
        // duration={0.75}
      >
        <H3>{pageQAFSubTitle}</H3>
      </AnimationOnScroll>
      <AnimationOnScroll
        animateIn="animate__fadeInRight"
        animateOnce
        offset={50}
        // animateOut="animate__fadeOutLeft"
        // duration={0.75}
      >
        <H5Big>— {removeHTMLTags(pageQAFDescriptionOne) || ""}</H5Big>
      </AnimationOnScroll>
      <label>
        <Search color={"var(--white)"} />
        <input
          type="text"
          value={searchValue}
          onChange={(e) => {
            setIsPristine(false);
            setSearchValue(e.target.value);
          }}
        />
      </label>
      {isPristine ? null : (
        <p style={{ color: "var(--white)" }}>
          {totalResults
            ? `${totalResults} search results across ${totalCategories} categories`
            : "No results. Try changing what you're searching for."}
        </p>
      )}
    </SeachFieldWrapper>
  );
};

export default SearchField;
