import React, { useEffect, useState } from "react";
import { H3 } from "./../Typography";
import EventList from "./../EventList";
import CommunityCardHolder from "./../CommunityCardHolder";

import { SearchResultsWrapper, ResultGrid, ResultsBlock } from "./elements";

const SearchResults = ({ eventsResults, participantsResults }) => {
  const [randomParticipantArray, setRandomParticipantArray] = useState([]);

  useEffect(() => {
    const temp = [];
    for (let i = 0; i < participantsResults.length; i++) {
      let rand = Math.floor(Math.random() * (3 - 1 + 1) + 1);
      if (participantsResults[i].participantType[0] === "Food") {
        rand = -1;
      }
      temp.push(rand);
    }
    setRandomParticipantArray(temp);
  }, [participantsResults]);

  return (
    <SearchResultsWrapper>
      {eventsResults.length ? (
        <ResultsBlock>
          <H3>Events</H3>
          <ResultGrid>
            <EventList events={eventsResults} />
          </ResultGrid>
        </ResultsBlock>
      ) : null}
      {participantsResults.length ? (
        <ResultsBlock>
          <H3>Participants</H3>
          <ResultGrid>
            <CommunityCardHolder
              cards={participantsResults}
              closeOverlay={false}
              randomArray={randomParticipantArray}
              cardsOrigin={participantsResults}
            />
          </ResultGrid>
        </ResultsBlock>
      ) : null}
    </SearchResultsWrapper>
  );
};

export default SearchResults;
